<template>
  <div class="container_m">
    <div class="earnings_clone" style="background:#fff" @click="cloneBtn">
      <img style="width:15px;height:15px" src="../../assets/leftBtn.png" />
      <div>返回</div>
    </div>
    <div class="header_top_m">
      <div>
        <div @click="selectBtn" class="header_item" :style="headerBtnShow?'border-bottom: 2px solid rgba(29, 140, 254, 1);color:rgba(29, 140, 254, 1)':''">待收货</div>
        <div class="bar" v-if="total">{{total}}</div>
      </div>
      <div>
        <div @click="selectBtn" class="header_item" :style="headerBtnShow?'':'border-bottom: 2px solid rgba(29, 140, 254, 1);color:rgba(29, 140, 254, 1)'">已完成</div>
      </div>
    </div>
    <div class="content_m">
      <div class="content_item" v-for="(item,index) in dataList" :key="index">
        <div>
          <img src="../../assets/Slice 55@2x.png" />
        </div>
        <div class="content_info" style="margin-left:10px">
          <div class="content_title">{{item.union_text}}</div>
          <div class="text">物料：{{item.union_text_s}}</div>
          <div class="text">时间：{{item.union_type!=3?item.shipments.create_time:item.data.express_at}} </div>
        </div>
        <div class="right_btn">
          <div class="shimp" style="color:#1D8CFE" @click="location(item)">查看物流</div>
          <div class="deliver" v-if="headerBtnShow" @click="deliver(item)">确认收货</div>
        </div>
      </div>
    </div>
    <div v-if="dataList.length==0" class="list_text">暂无数据</div>
  </div>
</template>
<script>
import {
  getMaterialList_api,
  getMemberSList_api,
  getOnleMemberSList_api
} from "@/api/admin";
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      headerBtnShow: true,
      queryList: {
        page: 1,
        limit: 10,
        shipments_state: 1
      },
      dataList: [],
      total: 0
    };
  },
  components: {},
  async created() {
    document.title = "物料中心";
    // 判断用户
    this.getList();
    // this.getToken();
    // this.getCode()
    // 1股东报名2股东报名3会员报名4工分增值5股份受让6股份转让7会员续签8合约续签9股份还款
  },
  methods: {
    cloneBtn() {
      this.$router.go(-1);
    },

    deliver(row) {
      Dialog.confirm({
        title: "提示",
        message: "确定收货"
      })
        .then(() => {
          this.deleteOrder(row);
        })
        .catch(() => {
          console.log(1);
        });
    },
    deleteOrder(row) {
      let data = {};
      console.log(row);
      if (row.union_type == 3) {
        data = {
          express_state: 3
        };
        getMemberSList_api(data, row.audit_id).then(res => {
          console.log(res);
          if (res.code == 0) {
            Toast({
              message: "操作成功",
              icon: "success",
              duration: 1000
            });
            this.dataList = [];
            this.getList();
          } else {
            Toast({
              message: res.error,
              icon: "error",
              duration: 1000
            });
          }
        });
      } else {
        getOnleMemberSList_api(row.shipments.shipments_id).then(res => {
          console.log(res);
          if (res.code == 0) {
            Toast({
              message: "操作成功",
              icon: "success",
              duration: 1000
            });
            this.dataList = [];
            this.getList();
          } else {
            Toast({
              message: res.error,
              icon: "error",
              duration: 1000
            });
          }
        });
      }
    },
    // 上拉加载------------------
    onRefresh(done) {
      this.queryList.page++;
      this.getList();
      done();
    },
    getList() {
      getMaterialList_api(this.queryList).then(res => {
        if (res.code == 0) {
          console.log(res);

          if (this.headerBtnShow) {
            this.total = res.pagination.total;
          }
          res.data.forEach(e => {
            switch (e.union_type) {
              case 1:
                e.union_text = "股东报名";
                e.union_text_s = "销售合约,工分合约";
                break;
              case 2:
                e.union_text = "股东报名";
                e.union_text_s = "销售合约,工分合约";
                break;
              case 3:
                e.union_text = "会员报名";
                e.union_text_s = "会员证，申请书";
                break;
              case 4:
                e.union_text = "工分增值";
                if (e.data.shares_amount == 0) {
                  e.union_text_s = "工分合约，居间协议，申请书";
                } else {
                  e.union_text_s = "居间协议,申请书";
                }

                break;
              case 5:
                e.union_text = "股份受让";
                e.union_text_s = "工分合约，居间协议，申请书";
                break;
              case 6:
                e.union_text = "股份转让";
                e.union_text_s = "会员证,销售合约";
                break;
              case 7:
                e.union_text = "会员续签";
                e.union_text_s = "工分合约，居间协议，申请书";
                break;
              case 8:
                e.union_text = "合约续签";
                e.union_text_s = "工分合约，居间协议，申请书";
                break;
              case 10:
                e.union_text = "物料变更";
                e.union_text_s = "分红股合约";
                break;
              default:
                e.union_text = "股份还款";
            }
          });
          this.dataList = this.dataList.concat(res.data);
        }
      });
    },
    selectBtn() {
      this.headerBtnShow = !this.headerBtnShow;
      this.dataList = [];
      this.queryList.page = 1;
      this.queryList.shipments_state = this.headerBtnShow ? 1 : 0;
      this.getList();
    },
    location(row) {
      if (row.union_type == 3) {
        console.log(row.data.express_info);
        this.$router.push(
          `./shipmentsList?data=${encodeURIComponent(
            JSON.stringify(row.data.express_info)
          )}`
        );
      } else {
        this.$router.push(
          `./shipmentsList?address=${row.data.audit_shipments?row.data.audit_shipments.receiver_address:''}&data=${encodeURIComponent(
            JSON.stringify(row.shipments.shipments_info)
          )}`
        );
      }
    }
  }
};
</script>
<style>
.header_top_m {
  display: flex;
  background-color: #fff;
  margin-top: 10px;
}
.header_top_m > div {
  flex: 1;
  text-align: center;
  font-size: 30px;
  padding-top: 30px;
  position: relative;
}
.container_m {
  background-color: #f4f4f4;
  height: 100%;
}
.shimp {
  width: 160px;
  height: 54px;
  background: #f1f1f1;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  font-size: 28px;
  text-align: center;
  line-height: 54px;
}
.header_item {
  width: 100px;
  margin: 0 auto;
  padding-bottom: 10px;
}
.content_item {
  width: 700px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(220, 220, 220, 0.1);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin: 20px 25px 0;
  padding: 35px 39px;
  box-sizing: border-box;
  font-size: 24px;
  display: flex;
  position: relative;
}
.right_btn {
  position: absolute;
  top: 40px;
  right: 20px;
  font-size: 26px;
}
.content_item img {
  width: 38px;
  height: 44px;
}
.right_btn .deliver {
  width: 160px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  background: #1d8cfe;
  font-size: 28px;
  color:#fff;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  margin-top:10px
}
.content_title {
  color: #000;
  font-size: 32px;
  margin-bottom: 10px;
}
.content_info .text {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.4);
}
.bar {
  position: absolute;
  top: 14px;
  left: 220px;
  width: 30px;
  height: 30px;
  background: #fe3e4d;
  opacity: 1;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
}
.list_text {
  text-align: center;
  margin-top: 40px;
  font-size: 40px;
}
.earnings_clone {
  padding-left: 31px;
  color: #000000;
  font-size: 15px;
  font-weight: 800;
  padding-top: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.van-toast {
  width: 300px;
  min-height: 120px;
}
</style>